import { Component } from '@angular/core';
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: 'app-create-request-success-modal',
  templateUrl: './create-request-success-modal.component.html',
  styleUrls: ['./create-request-success-modal.component.scss']
})
export class CreateRequestSuccessModalComponent {

  message:string = ''
  constructor(public modal:NgbModal) {
  }

}

<div class="nd-container" (click)="modal.dismissAll()">
  <div>
    <svg width="68" height="68" viewBox="0 0 68 68" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M33.9997 3.45642C17.1314 3.45642 3.4563 17.1315 3.4563 33.9998C3.4563 50.8681 17.1314 64.5432 33.9997 64.5432C50.868 64.5432 64.5431 50.8681 64.5431 33.9998C64.5431 17.1315 50.868 3.45642 33.9997 3.45642ZM47.2389 28.8352C47.4827 28.5565 47.6682 28.2319 47.7847 27.8805C47.9012 27.5291 47.9462 27.1579 47.9171 26.7888C47.888 26.4197 47.7854 26.0601 47.6153 25.7312C47.4452 25.4024 47.2111 25.1109 46.9266 24.8738C46.6422 24.6368 46.3132 24.4591 45.9591 24.3511C45.605 24.243 45.2328 24.2069 44.8645 24.2449C44.4962 24.2828 44.1392 24.3941 43.8145 24.572C43.4898 24.7499 43.204 24.991 42.9739 25.2811L31.0342 39.6059L24.8561 33.425C24.3324 32.9193 23.631 32.6394 22.903 32.6457C22.175 32.652 21.4785 32.9441 20.9637 33.4589C20.4489 33.9737 20.1569 34.6701 20.1506 35.3981C20.1442 36.1262 20.4241 36.8276 20.9299 37.3513L29.2599 45.6813C29.5327 45.9539 29.8594 46.1668 30.219 46.3062C30.5787 46.4456 30.9634 46.5086 31.3487 46.4911C31.734 46.4736 32.1115 46.376 32.457 46.2045C32.8025 46.0331 33.1085 45.7915 33.3555 45.4952L47.2389 28.8352Z" fill="#84D65A"/>
    </svg>

  </div>

    <div class="d-flex align-items-center justify-content-center flex-column gap-2">
      <div class="nd-title">Success!</div>
      <div class="nd-message">{{message}}</div>
    </div>
</div>
